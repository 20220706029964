<template>
  <div class="container my-2" v-if="getRequest">
    <div class="row mt-5 container">
      <div class="bd-example w-100">
        <div
          id="carousel-home"
          class="carousel slide row d-flex justify-content-center"
          data-ride="carousel"
          style="height: 20rem"
        >
          <div class="carousel-inner w-100 h-100">
            <div
              class="carousel-item pt-0 w-100 h-100"
              v-for="(slide, i) in getRequest.images"
              :class="{ active: isOne(i) }"
            >
              <img
                rel="preload"
                class="d-block mx-auto w-100 h-100"
                :src="'https://images.scrapiz.com/' + slide"
                alt="part image"
              />
            </div>
          </div>

          <div class="row">
            <ol class="carousel-indicators">
              <li
                v-for="(slide, i) in getRequest.images"
                data-target="#carousel-home"
                :data-slide-to="i"
                :class="{ active: isOne(i) }"
              ></li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-12 row justify-content-between">
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
          <span class="form-control text-center">
            {{ getRequest.manufacturer }}
          </span>
          <span class="input-group-text bw-span">الشركة المصنعة</span>
        </div>
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
          <span class="form-control text-center">
            {{ getRequest.model }}
          </span>
          <span class="input-group-text bw-span">السيارة</span>
        </div>
      </div>

      <div class="col-lg-12 row justify-content-between">
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
          <span class="form-control text-center">
            {{ getRequest.year }}
          </span>
          <span class="input-group-text bw-span">سنة الصنع</span>
        </div>
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
          <span class="form-control text-center">
            {{ getRequest.name }}
          </span>
          <span class="input-group-text bw-span">اسم القطعة</span>
        </div>
      </div>

      <div class="col-lg-12 row justify-content-between">
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
          <span class="form-control text-center">
            {{ getRequest.location }}
          </span>
          <span class="input-group-text bw-span">المدينة</span>
        </div>
        <div
          class="input-group input-group-lg mb-3 col-lg-6 col-sm-12"
          style="direction: ltr"
        >
        
          <span class="form-control text-center">
            <p style="display:inline-block" class="mr-1"> ريال </p>
             <p style="display:inline-block"> {{ getRequest.lowestOffer }}  </p > 
          </span>
          
          <span class="input-group-text bw-span">اقل عرض</span>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div
        class="input-group input-group-lg"
        style="direction: ltr; height: 8rem"
      >
        <span
          class="form-control text-center"
          style="direction: ltr; height: 100%"
        >
          {{ getRequest.description }}
        </span>

        <span class="input-group-text">معلومات إضافية</span>
      </div>
    </div>

    <div class="col-12 my-4 text-center">
      <button class="btn btn-lg bwj-btn" @click="showModal = true">
        إضافة عرض
      </button>
    </div>
    <div class="vld-overlay d-flex align-items-center" v-if="showModal">
      <div
        class="vld-background"
        style="background: rgba(0, 0, 0, 0.3) !important"
      ></div>
      <transition name="fade" appear>
        <transition name="slide" appear>
          <div class="MyModal text-center" v-if="showModal">
            <div class="text-right">
              <span @click="closeModal">
                <i class="close icon" style="font-size: 1.4em"></i>
              </span>
            </div>
            <add-offer v-bind:selectedRequest="getRequest"></add-offer>
          </div>
        </transition>
      </transition>
    </div>
  </div>
</template>

<script>
import { GET_FULL_REQUST } from "../../graphql/queries";
import addOffer from "./addOffer.vue";

export default {
  data() {
    return {
      getRequest: null,
      routerParm: this.$route.params.requestID,
    };
  },
  apollo: {
    getRequest: {
      query: GET_FULL_REQUST,
      variables() {
        return {
          RequestID: this.routerParm,
        };
      },
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    "add-offer": addOffer,
  },
  methods: {
    isOne(index) {
      if (index == 0) {
        return true;
      }
    },
    closeModal() {
      console.log("modal closed");
      this.showModal = false;
    },
  },
  computed: {
    showModal: {
      set(value) {
        this.$store.state.glopal.showModal = value;
        return;
      },
      get() {
        return this.$store.state.glopal.showModal;
      },
    },
  },
};
</script>

<style scoped>
.bw-span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.row {
margin-left: 0!important;
margin-right: 0!important;
}
</style>
